<script setup lang="ts">
import { ref, inject, onMounted, onUnmounted, type ShallowRef } from "vue";
import Button from "primevue/button";
import Divider from "primevue/divider";
import type { Editor } from "@tiptap/vue-3";

import ProgressSpinner from "@/components/LoadingState/ProgressSpinner.vue";
import { AdminApi } from "@/services/AdminApi";
import { useAdminStore } from "@/stores/useAdminStore";
import { ROUTES } from "@/models/routes";
import {
  sanitizeHtml,
  validateLineBreakMarks,
} from "./TipTap/Extensions/TipTapAnnotationHelper";
const props = defineProps({
  isPapyrusLoading: Boolean,
  onSetContent: Function,
  onReload: Function,
});
const hasSaveError = ref(false);
const isSaving = ref(false);
const adminStore = useAdminStore();
const editor = inject("editor") as ShallowRef<Editor | undefined>;

onMounted(() => {
  window.addEventListener("keydown", handleSaveShortcut);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleSaveShortcut);
});
const handleSaveShortcut = (event: KeyboardEvent) => {
  if ((event.ctrlKey || event.metaKey) && event.key === "s") {
    event.preventDefault();
    if (adminStore.data.dialog.type !== "closed") {
      return false;
    }
    onSave();
  }
};
const onOpenView = () => {
  window.open(
    `${ROUTES.PAPYRUS.path}/${adminStore.data.selectedPapyrusId}`,
    "_blank",
  );
};

const onSave = async () => {
  if (
    !adminStore.data.isTextEditorDirty ||
    props.isPapyrusLoading ||
    isSaving.value
  ) {
    return false;
  }

  if (editor && editor.value) {
    let payload = {};
    const currentHtml = editor.value?.getHTML() || "";
    const errors = validateLineBreakMarks(editor.value.state);
    if (errors.length) {
      adminStore.data.message = {
        type: "error",
        content: `Document divisions must include a line break. 
        Fix the following line break marks: '${errors.join("', '")}'`,
      };
      return false;
    }
    const normalizedHtml = sanitizeHtml(currentHtml);
    if (props.onSetContent) {
      props.onSetContent(editor.value, normalizedHtml);
    }
    isSaving.value = true;
    if (adminStore.data.selectedTextType === "Original") {
      payload = {
        greekText: editor.value?.getHTML() || "",
      };
    } else {
      payload = {
        englishText: editor.value?.getHTML() || "",
      };
    }
    try {
      await AdminApi.updatePapyrus(adminStore.data.selectedPapyrusId, payload);
      hasSaveError.value = false;
      adminStore.data.message = {
        type: "success",
        content: "Papyrus saved",
      };
      adminStore.data.isTextEditorDirty = false;
    } catch (error) {
      hasSaveError.value = true;
    }
    isSaving.value = false;
  }
};
const onShowNotes = () => {
  useAdminStore().data.dialog = {
    type: "edit-papyrus-text-notes",
    title: "Edit notes",
  };
};
</script>

<template>
  <div class="editor-content-loading" v-if="isPapyrusLoading">
    <ProgressSpinner />
  </div>

  <div class="editor-toolbar-bottom">
    <Button
      :disabled="isPapyrusLoading"
      class="editor-toolbar-button-show-notes"
      label="Notes"
      title="Show internal notes"
      icon="pi pi-paperclip"
      severity="help"
      @click="onShowNotes"></Button>
    <Divider style="margin-right: 24px" layout="vertical" />
    <Button
      :disabled="isPapyrusLoading"
      label="Reload"
      icon="pi pi-refresh"
      severity="info"
      class="editor-toolbar-reload"
      @click="
        () => {
          if (props.onReload) props.onReload();
        }
      "></Button>
    <Button
      :disabled="isPapyrusLoading"
      label="View"
      icon="pi pi-eye"
      severity="info"
      class="editor-toolbar-preview"
      @click="onOpenView()"></Button>
    <Button
      :disabled="!adminStore.data.isTextEditorDirty || isPapyrusLoading"
      label="Save"
      icon="pi pi-check"
      severity="success"
      class="editor-toolbar-save"
      :loading="isSaving"
      @click="onSave"></Button>
  </div>
  <div class="editor-error editor-toolbar-save-error" v-if="hasSaveError">
    Error while saving. Please reload the page and try again.
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";

.editor-toolbar-bottom {
  display: flex;
  justify-content: end;
  border: 1px solid $container-divider-color;
  padding: 8px;
}
.editor-content-loading {
  min-height: 200px;
}

.editor-toolbar-preview {
  margin-right: 4px;
}

.editor-toolbar-save-error {
  text-align: right;
  padding: 4px;
}

.papyrus-text-col-row-number {
  color: $archeion-yellow;
  margin-top: 4px;
  @include very-tiny-normal;
}

.editor-toolbar-bottom {
  .editor-toolbar-reload {
    @include archeion-editor-toolbar-button;
    @include archeion-black-button;
  }
  .editor-toolbar-preview {
    @include archeion-editor-toolbar-button;
  }
  .editor-toolbar-save {
    @include archeion-editor-toolbar-button;
  }
}
.editor-toolbar-button-show-notes {
  @include archeion-editor-toolbar-button;
}
</style>
