import { DateTime } from "luxon";
import type { HistoricDate, Papyrus } from "@/models/strapi";

// hardcoded for now
export const getBaseStrapiUrl = () => {
  if (document.URL.startsWith("http://localhost")) {
    return "https://archeion-backend.test.ius.uzh.ch";
    return "http://localhost:1337"; //"https://archeion-backend.test.ius.uzh.ch"; // "http://localhost:1337"
  } else if (document.URL.startsWith("https://archeion.test.ius.uzh.ch")) {
    return "https://archeion-backend.test.ius.uzh.ch";
  } else if (document.URL.startsWith("https://archeion.ius.uzh.ch")) {
    return "https://archeion-backend.ius.uzh.ch";
  }
};

export const formatDate = (date: HistoricDate) => {
  let dateStr = "";
  const parsedDate = DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
  });
  if (parsedDate.isValid) {
    const yearStr =
      parsedDate.year < 0
        ? `${parsedDate.year * -1 + 1} BCE`
        : `${parsedDate.year} CE`;
    dateStr = `${yearStr}, ${parsedDate.toFormat("LLLL d")}`;
    dateStr = dateStr.replace(", January 1", "");
    return dateStr;
  } else {
    return dateStr;
  }
};

export const getDateStr = (dateFrom: HistoricDate, dateTo: HistoricDate) => {
  return formatDate(dateFrom) === formatDate(dateTo)
    ? formatDate(dateFrom)
    : `${formatDate(dateFrom)} - ${formatDate(dateTo)}`;
};

export const getStrapiAdminUrl = () => {
  return getBaseStrapiUrl() + "/admin";
};
// hardcoded for now
export const isProduction = () => {
  return document.URL.startsWith("https://archeion.ius.uzh.ch");
};

const truncateString = (str: string) => {
  const MAX_LENGTH_OPTION = 70;
  return str.length > MAX_LENGTH_OPTION
    ? str.substring(0, MAX_LENGTH_OPTION) + "..."
    : str;
};

export const getPapyrusOptionLabel = (papyrus: Papyrus) => {
  const name = `${papyrus.editions[0].series} ${papyrus.editions[0].volume} ${papyrus.editions[0].documentNo} - ${papyrus.title}`;
  return truncateString(name);
};

export function loadExternalScript(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}
export function loadExternalCSS(href: string) {
  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = href;
    link.type = "text/css";
    link.onload = resolve;
    link.onerror = reject;
    document.head.appendChild(link);
  });
}
export const LOCAL_STORAGE_JWT = "jwt";
export const LOCAL_STORAGE_JWT_EXPIRY = "jwt-expiry";
export const LOCAL_STORAGE_ADMIN_LOGIN = "admin-login";
export const LOCAL_STORAGE_ADMIN_PASSWORD = "admin-password";
export const LOCAL_STORAGE_ADMIN_USERNAME = "admin-username";

export const TOAST_TIMEOUT = 4000;
