import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";

import NotFoundView from "@/views/NotFoundView.vue";
import HomeView from "@/views/Home/HomeView.vue";
import PapyrusView from "@/views/Papyrus/PapyrusView.vue";
import SearchView from "@/views/Search/SearchView.vue";
import TermsView from "@/views/TermsView.vue";
import AboutArcheionView from "@/views/AboutArcheionView.vue";
import LiteratureListView from "@/views/LiteratureListView.vue";
import TeamView from "@/views/TeamView.vue";
import TimelineView from "@/views/Timeline/TimelineView.vue";

import AdminView from "@/views/Admin/AdminView.vue";
import AnnotationEditorLogin from "@/views/Admin/Login/LoginView.vue";
import { ROUTES } from "@/models/routes";
import { AdminApi } from "@/services/AdminApi";

const routes: Array<RouteRecordRaw> = [
  // PUBLIC ROUTES
  {
    path: ROUTES.HOME.path,
    name: ROUTES.HOME.name,
    component: HomeView,
  },
  {
    path: ROUTES.SEARCH.path,
    name: ROUTES.SEARCH.name,
    component: SearchView,
  },
  {
    path: `${ROUTES.PAPYRUS.path}/:id?`,
    name: ROUTES.PAPYRUS.name,
    component: PapyrusView,
  },
  {
    path: `${ROUTES.LITERATURE.path}`,
    name: ROUTES.LITERATURE.name,
    component: LiteratureListView,
  },
  {
    path: ROUTES.TEAM.path,
    name: ROUTES.TEAM.name,
    component: TeamView,
  },
  {
    path: ROUTES.ABOUT.path,
    name: ROUTES.ABOUT.name,
    component: AboutArcheionView,
  },
  {
    path: ROUTES.TERMS.path,
    name: ROUTES.TERMS.name,
    component: TermsView,
  },
  {
    path: ROUTES.TIMELINE.path,
    name: ROUTES.TIMELINE.name,
    component: TimelineView,
  },
  // ADMIN
  {
    path: ROUTES.ADMIN.path,
    redirect: ROUTES.ANNOTATION_EDITOR_LOGIN.path,
  },
  // use login page for logout
  {
    path: ROUTES.ADMIN_LOGOUT.path,
    redirect: ROUTES.ANNOTATION_EDITOR_LOGIN.path + "?logout=1",
  },
  {
    path: ROUTES.ANNOTATION_EDITOR_LOGIN.path,
    name: ROUTES.ANNOTATION_EDITOR_LOGIN.name,
    component: AnnotationEditorLogin,
  },
  {
    path: `${ROUTES.ANNOTATION_EDITOR.path}/:id?`,
    name: ROUTES.ANNOTATION_EDITOR.name,
    component: AdminView,
    meta: { requiresAuth: true },
  },
  {
    path: `${ROUTES.ANNOTATION_MANAGER.path}/:id?`,
    name: ROUTES.ANNOTATION_MANAGER.name,
    component: AdminView,
    meta: { requiresAuth: true },
  },
  {
    path: `${ROUTES.ANNOTATION_HELP.path}/:id?`,
    name: ROUTES.ANNOTATION_HELP.name,
    component: AdminView,
    meta: { requiresAuth: true },
  },

  // 404
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.query.scrollToTop === "false") {
      return false;
    }
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!AdminApi.isLoggedIn()) {
      next({ name: ROUTES.ANNOTATION_EDITOR_LOGIN.name });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
