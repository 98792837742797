import { uid } from "uid";
import { parse as htmlParse } from "node-html-parser";
import type { EditorState } from "@tiptap/pm/state";
import type { Editor } from "@tiptap/vue-3";
import type { Node } from "@tiptap/pm/model";
import type { Mark } from "prosemirror-model";
import type {
  HTMLElement as NodeParserHTMLElement,
  Node as NodeParserNode,
} from "node-html-parser";

import {
  LINEBREAK_MARK_NAME,
  ANNOTATION_CONTENT_IDENTIFIER,
  ANNOTATION_INTERNAL_IDENTIFIER,
  ANNOTATION_MARK_NAME,
} from "@/models/papyrusText";

function containsInvalidSpanTags(state: EditorState): boolean {
  let hasDisallowedTags = false;
  const { from, to } = state.selection;
  state.doc.nodesBetween(from, to, (node) => {
    node.marks.forEach((mark) => {
      if (
        mark.type.name !== LINEBREAK_MARK_NAME &&
        mark.type.spec.spanning !== false
      ) {
        hasDisallowedTags = true;
      }
    });
  });
  return hasDisallowedTags;
}

export const removeMarks = (editor: Editor): void => {
  const { state } = editor;
  const { from, to } = state.selection;
  const transaction = state.tr;
  state.doc.nodesBetween(from, to, (node, pos) => {
    node.marks.forEach((mark) => {
      transaction.removeMark(pos, pos + node.nodeSize, mark.type);
    });
  });
  if (transaction.docChanged) editor.view.dispatch(transaction);
};

function containsTextOnly(state: EditorState): boolean {
  const { from, to } = state.selection;
  let nonTextNodes = 0;
  state.doc.nodesBetween(from, to, (node) => {
    nonTextNodes += node.marks.length;
  });

  return nonTextNodes === 0;
}

function getUniqueIdentifier(): string {
  return uid(32);
}

function addSingleAnnotationMark(editor: Editor, annotationId: number) {
  editor.commands.setMark(ANNOTATION_MARK_NAME, {
    title: annotationId,
    [ANNOTATION_CONTENT_IDENTIFIER]: annotationId,
    [ANNOTATION_INTERNAL_IDENTIFIER]: getUniqueIdentifier(),
  });
}

function addMultipleAnnotationMarks(editor: Editor, annotationId: number) {
  const { tr, schema } = editor.state;
  const targetMarksNames = [LINEBREAK_MARK_NAME];
  const marksToApply = schema.marks[ANNOTATION_MARK_NAME].create({
    title: annotationId,
    [ANNOTATION_CONTENT_IDENTIFIER]: annotationId,
    [ANNOTATION_INTERNAL_IDENTIFIER]: getUniqueIdentifier(),
  });

  function applyMark(from: number, to: number) {
    const textContent = editor.state.doc.textBetween(from, to, " ");
    const trimmedStart = textContent.search(/\S/); // Index of first non-whitespace char
    const trimmedEnd = textContent.search(/\S\s*$/) + 1; // Index after last non-whitespace char

    if (trimmedStart >= 0 && trimmedEnd > 0) {
      tr.addMark(from + trimmedStart, from + trimmedEnd, marksToApply);
    }
  }

  let lastMarkEnd = 0;
  let firstMarkFound = false;
  const selectionFrom = editor.state.selection.from;
  const selectionTo = editor.state.selection.to;

  editor.state.doc.nodesBetween(
    selectionFrom,
    selectionTo,
    (node: Node, pos: number) => {
      if (!node.isInline) return;
      const nodeMarks = node.marks.filter((mark: Mark) =>
        targetMarksNames.includes(mark.type.name),
      );
      if (nodeMarks.length > 0) {
        if (!firstMarkFound) {
          if (pos > selectionFrom) {
            applyMark(selectionFrom, pos);
          }
          firstMarkFound = true;
        } else if (lastMarkEnd !== 0 && pos > lastMarkEnd) {
          applyMark(lastMarkEnd, pos);
        }
        lastMarkEnd = pos + node.nodeSize;
      }
    },
  );
  if (lastMarkEnd < selectionTo) {
    applyMark(lastMarkEnd, selectionTo);
  }
  if (tr.docChanged) {
    editor.view.dispatch(tr);
  }
}

export function checkSelectedText(state: EditorState): string[] {
  const errors: string[] = [];
  if (containsInvalidSpanTags(state)) {
    errors.push(
      `Part of the selected text includes a non-allowed mark. Please select a different text.`,
    );
  }
  return errors;
}

export function distributeAnnotationsTags(
  editor: Editor,
  annotationId: number,
) {
  if (containsTextOnly(editor.state)) {
    addSingleAnnotationMark(editor, annotationId);
  } else {
    addMultipleAnnotationMarks(editor, annotationId);
  }
}

// remove all non span, br tags
export function sanitizeHtml(html: string): string {
  const root: NodeParserHTMLElement = htmlParse(html);

  function unwrapElement(element: NodeParserHTMLElement): void {
    element.childNodes.forEach((child: NodeParserNode) => {
      if (child.nodeType === 1) {
        unwrapElement(child as NodeParserHTMLElement);
      }
    });

    // If the current element is not a span or br, unwrap it
    if (
      element.tagName &&
      element.tagName.toLowerCase() !== "span" &&
      element.tagName.toLowerCase() !== "br"
    ) {
      if (element.parentNode) {
        element.replaceWith(element.innerHTML);
      }
    }
  }
  unwrapElement(root);
  return root.toString();
}

export function getCurrentAnnotations(editor: Editor) {
  const { doc } = editor.state;
  const annotations: Mark[] = [];

  doc.descendants((node) => {
    node.marks.forEach((mark: Mark) => {
      if (mark.type.name === ANNOTATION_MARK_NAME) {
        annotations.push(mark);
      }
    });
  });

  return annotations;
}

/* 
- unused for now

export function findAndRemoveOrphanedAnnotations(editor: Editor) {
  const annotations = getCurrentAnnotations(editor);
  const errors: string[] = [];
  annotations.forEach((annotation) => {
    const annotationId = annotation.attrs[ANNOTATION_CONTENT_IDENTIFIER];
    const annotationInternalId =
      annotation.attrs[ANNOTATION_INTERNAL_IDENTIFIER];
    const foundAnnotation = useAdminStore().getAnnotation(annotationId);

    if (!foundAnnotation) {
      errors.push(
        `annotationId: ${annotationId}\n annotationInternalId: ${annotationInternalId}`,
      );
      editor.chain().focus().deleteAnnotation(annotationInternalId).run();
    }

    if (errors.length) {
      useAdminStore().data.message = {
        type: "error",
        content: "Error: Could not load annotations: " + errors.join("\n\n"),
      };
    }
  });
}
*/
export const checkCursorInsideMark = (editorState: EditorState): boolean => {
  if (!editorState || !editorState.selection) return false;

  const { from } = editorState.selection;
  const resolvedPos = editorState.doc.resolve(from);
  const marks = resolvedPos.marks();

  return marks && marks.length > 0;
};

export const validateLineBreakMarks = (editorState: EditorState): string[] => {
  const errors: string[] = [];
  const { doc } = editorState;

  doc.descendants((node) => {
    // Get the text content of the node
    const nodeTextContent = node.textContent || "";

    node.marks.forEach((mark: Mark) => {
      if (mark.type.name === LINEBREAK_MARK_NAME) {
        const startsWithNumber = /^\d/.test(nodeTextContent);

        const pipeFollowedByNumber = /\|\s*\d+$/.test(nodeTextContent);

        if (!startsWithNumber && !pipeFollowedByNumber) {
          errors.push(nodeTextContent);
        }
      }
    });
  });

  return errors;
};
